import AuthGuard from "app/auth/AuthGuard";
import dashboardRoutes from "app/views/dashboard/DashboardRoutes";
import NotFound from "app/views/sessions/NotFound";
import sessionRoutes from "app/views/sessions/SessionRoutes";
import { Navigate, useParams } from "react-router-dom";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import postRoutes from "./views/post/PostRoutes";
import profileRoutes from "./views/profile/ProfileRoutes";
import userRoutes from "./views/users/UserRoutes";
import PrivacyPolicy from "./views/singlepage/PrivacyPolicy";
import TermsCondition from "./views/singlepage/TermsCondition";
import StoreLink from "./views/singlepage/StoreLink";

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      ...userRoutes,
      ...postRoutes,
      ...profileRoutes,
    ],
  },
  ...sessionRoutes,

  { path: "/", element: <Navigate to="dashboard" /> },
  { path: "*", element: <NotFound /> },
  { path: "/privacypolicy", element: <PrivacyPolicy /> },
  { path: "/termsofservice", element: <TermsCondition /> },
  { path: "/storelink", element: <StoreLink /> }
];
export default routes;
