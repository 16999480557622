
const SocialMediaComponent = (props) => {
    return (
        <a href={props.url} target="_blank">
            <img className="ml-1 mr-2" style={{ width: '15%', height: '15%', ...props.iconStyle }} src={props.icon} />
        </a>

    )
}

export default SocialMediaComponent