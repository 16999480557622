
import { useEffect } from "react";
import { Provider } from "react-redux";
import { Route, Routes, useRoutes } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { MatxTheme } from "./components";
import { SettingsProvider } from "./contexts/SettingsContext";
import { Store } from "./redux/Store";
import { PageLayout } from "./components/PageLayout";
import routes from "./routes";
import "./styles/App.css";
import PrivacyPolicy from "./views/singlepage/PrivacyPolicy";
import TermsCondition from "./views/singlepage/TermsCondition";
import './styles/Font.css';
import DeleteAccountPage from "./views/singlepage/DeleteAccountPage";
import StoreLink from "./views/singlepage/StoreLink";
import { appStoreLink, playStoreLink } from "./constant";

const App = ({ instance }) => {

  const content = useRoutes(routes);

  useEffect(() => {
    if (window.location.pathname.includes('/shareaudition')) {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        window.open(playStoreLink, '_self');
        return "Android";
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.open(appStoreLink, '_self');
        return "iOS";
      }
    }
  }, [])
  let user = JSON.parse(sessionStorage.getItem("userJSON"));
  return (
    <Provider store={Store}>
      <SettingsProvider>
        <MatxTheme>
          <MsalProvider instance={instance}>
            <Routes>
              {user?.email ? (
                <></>
              ) : (
                <>
                  <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                  <Route path="/termsofservice" element={<TermsCondition />} />
                  <Route path="/storelink" element={<StoreLink />} />
                  <Route path="/deleteaccount" element={<DeleteAccountPage />} />
                </>
              )}
            </Routes>
            <PageLayout>{content}</PageLayout>
          </MsalProvider>
        </MatxTheme>
      </SettingsProvider>
    </Provider>
  );
};

export default App;
