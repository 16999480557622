import React, { createContext, useEffect, useReducer } from 'react'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import { useMsal } from '@azure/msal-react';
import { BASE_URL, endPointName } from 'apis/apiList';
import { CallAPI } from 'apis/callAPI';
import db from 'app/db/database';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import useAuth from 'app/hooks/useAuth';

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const location = useLocation();
    const [state, dispatch] = useReducer(reducer, initialState)
    const { instance, inProgress, accounts } = useMsal();
    const path = location.pathname;
    const navigate = useNavigate();
    const login = async (email, password) => {
        const res = await axios.post(BASE_URL + endPointName.Authorize + email, "")
        setTimeout(() => { }, 1000)
        const { data } = res
        let userData = {
            id: '1',
            role: 'SA',
            name: data.userName,
            userName: data.userName,
            email: data.emailId,
            avatar: '/assets/images/face-6.jpg',
            profilePic: data.profilePic,
            age: 25,
            adminUserId: data.adminUserId,
            isActive: data.isActive,
            roleId: data.roleId,
            whenEntered: data.whenEntered,
            whenModified: data.whenModified
        };

        if (data.isActive) {
            setSession("eyJleHAiOjE2NzE3MTc3NjcsIm5iZiI6MTY3MTcxNDE2NywidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly90YWxlbnRodW50YjJjLmIyY2xvZ2luLmNvbS9mNjQzMjM1Ny05ZTc2LTRmNzYtYmUxMS01MGQ3ZGUwZjQ4MGUvdjIuMC8iLCJzdWIiOiJiNGUwNzkyNy04MDg4LTQ5M2UtYTYwZC05NzIyNjE3YzAwYjAiLCJhdWQiOiJlZjlmNzJmYi0wZDJjLTRkYzQtYTc4Yy0wY2Y4ZGMyN2YzMTgiLCJub25jZSI6IjA1YzQ5NDRlLTM4YmEtNGEyNC1hMWE4LWVmMDMwYWE5NjE4MyIsImlhdCI6MTY3MTcxNDE2NywiYXV0aF90aW1lIjoxNjcxNzE0MTYwLCJlbWFpbHMiOlsiYWppbmt5YS5iaGFza2FyQGlmaS50ZWNoIl0sInRmcCI6IkIyQ18xX1RhbGVudEh1bnQifQ==");
            dispatch({
                type: 'LOGIN',
                payload: {
                    user: userData,
                },
            })
        } else {

        }
    }

    const register = async (email, username, password) => {
        // const response = await axios.post('/api/auth/register', {
        //     email,
        //     username,
        //     password,
        // })

        // const { accessToken, user } = response.data

        // setSession(accessToken)

        // dispatch({
        //     type: 'REGISTER',
        //     payload: {
        //         user,
        //     },
        // })
    }

    const logout = async () => {
        setSession(null)
        // instance.logoutRedirect();
        db.delete().then(() => {
            console.log("Database successfully deleted");
        }).catch((err) => {
            console.error("Could not delete database");
        }).finally(() => {
            // Do what should be done next...
        });
        localStorage.clear();
        sessionStorage.clear();
        dispatch({ type: 'LOGOUT' })
        instance.logout();
    }

    useEffect(() => {
        ; (async () => {
            try {
                // debugger
                if (instance) {
                    console.log('active ==< ', instance.getActiveAccount())
                    let activeAccount = instance.getActiveAccount();
                    if (activeAccount) {

                        const response = await axios.post(BASE_URL + endPointName.authorizeUser + activeAccount.username, "")
                        const { data } = response

                        let user = {
                            id: activeAccount.localAccountId,
                            role: 'SA',
                            name: data.userName,
                            userName: data.userName,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            emailId: data.emailId,
                            avatar: '',
                            age: 25,
                            profilePic: data.profilePic,
                            adminUserId: data.adminUserId,
                            isActive: data.isActive,
                            roleId: data.roleId,
                            roleName: data.roleName,
                            whenEntered: data.whenEntered,
                            whenModified: data.whenModified,
                            organizationId: data.organizationId,
                            featuresList: {
                                showExistingRoles: data.featuresList.showExistingRoles,
                                showCreateUser: data.featuresList.showCreateUser,
                                showUsersAdmin: data.featuresList.showUsersAdmin,
                                showAddActor: data.featuresList.showAddActor,
                                showDashboard: data.featuresList.showDashboard,
                                showProjects: data.featuresList.showProjects,
                                showAuditions: data.featuresList.showAuditions,
                                showCreateAudition: data.featuresList.showCreateAudition,
                                showActors: data.featuresList.showActors,
                                showFeedback: data.featuresList.showFeedback,
                                showActorsManagement: data.featuresList.showActorsManagement,
                                showConfigure: data.featuresList.showConfigure
                            },
                            createdBy: data.createdBy
                        };
                        dispatch({
                            type: 'INIT',
                            payload: {
                                isAuthenticated: true,
                                user,
                            },
                        })
                        if (data.isActive) {
                            sessionStorage.setItem('userJSON', JSON.stringify(user))
                            let orgResponse = await CallAPI(endPointName.OrganizationMaster + data.organizationId, "post");
                            sessionStorage.setItem('organizationData', JSON.stringify(orgResponse))
                            const accessToken = window.localStorage.getItem('accessToken')
                            if (accessToken !== null && path == '/web') {
                                navigate('/dashboard');
                            }
                        } else {
                            Swal.fire({
                                title: '',
                                text: `User is Deactivated.`,
                                icon: 'warning',
                                confirmButtonText: 'Ok',
                                confirmButtonColor: '#F06C6A',
                                customClass: {
                                    container: 'swal2-container-zIndex'
                                }
                            }).then((val) => {
                                logout()
                            })
                        }
                    } else {
                        dispatch({
                            type: 'INIT',
                            payload: {
                                isAuthenticated: false,
                                user: null,
                            },
                        })
                    }

                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
                logout()
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
