import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, styled } from "@mui/material";
import { useRef } from "react";
import { CSVLink } from "react-csv";
import CloseIcon from '@mui/icons-material/Close';

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
}));

const ExportToExcel = ({ allData, handleClosePopUp, openPopUp, currentPageData, pathfileName }) => {
    const allDownload = useRef()
    const currentDownload = useRef()
    const exportAllFunc = () => {
        allDownload.current.link.click();
        handleClosePopUp()
    }
    const exportCurrentPageFunc = () => {
        currentDownload.current.link.click();
        handleClosePopUp()
    }
    return (
        <Box>
            <Dialog
                open={openPopUp}
                maxWidth={'sm'}
                onClose={handleClosePopUp}
                aria-labelledby="form-dialog-title"
            >

                <DialogTitle >
                    Export To Excel
                    <IconButton
                        aria-label="close"
                        onClick={handleClosePopUp}
                        sx={{
                            position: 'absolute',
                            right: 5,
                            color: "grey",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <CSVLink ref={allDownload} data={allData} filename={pathfileName} />
                    <StyledButton variant="contained" color="primary" onClick={() => exportAllFunc()}>
                        Export All
                    </StyledButton>
                    <CSVLink ref={currentDownload} data={currentPageData} filename={pathfileName} />
                    <StyledButton variant="contained" color="primary" onClick={() => exportCurrentPageFunc()} >
                        Export Current
                    </StyledButton>
                </DialogContent>
            </Dialog >
        </Box>
    )

}

export default ExportToExcel;