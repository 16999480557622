import React from 'react';
import CreateUpdatePost from 'app/components/Post/CreateUpdatePost';
import { Box, Divider, IconButton, styled } from '@mui/material';
// import Button from '@mui/material/Button';
// import { amber, green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close'

export default function UpdatePost({ openPopUp, onClose, postId, onSubmit, validTillExp }) {

    return (
        <Box>
            <Dialog
                open={openPopUp}
                fullWidth={true}
                maxWidth={'lg'}
                onClose={() => onClose()}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Update Audition
                    <IconButton
                        aria-label="close"
                        onClick={() => onClose()}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: "grey",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                </DialogTitle>
                <Divider />
                <DialogContent>

                    <CreateUpdatePost
                        use="update"
                        postId={postId}
                        validTillExp={validTillExp}
                        onClick={() => onClose()}
                        onSubmit={() => onSubmit()}
                    />

                </DialogContent>
            </Dialog>
        </Box>
    )
}
